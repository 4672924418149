
import React from "react"

import ListmanagerCategorytagPage from "../../components/negosyonow/listmanagers/categorytag"

const NNPARENTTAGID=40

const searchFields = [
	{"label":"Order", "dbfield": "nnchildtag_order", "type": "integer", "filtertype": "integer"},
	{"label":"Image", "dbfield": "nnchildtag_image", "type": "image", "filtertype": "image"},
	{"label":"Link", "dbfield": "nnchildtag_url", "type": "text", "filtertype": "text"},
];


const formFields = [
	[
		{"label":"Order", "field": "nnchildtag_order", "value": "", "input": "integer", "mode": "required"},
		{"label":"Banner", "field": "nnchildtag_image", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/banner"},
		{"label":"Link", "field": "nnchildtag_url", "value": "", "input": "textbox", "mode": "normal", "info":"start after https://negosyonow.com/, or enter full address"},
	]
];




const BannerPage = ({location}) => {


	return <ListmanagerCategorytagPage
			location={location}
			parenttagid={NNPARENTTAGID}
			formFields={formFields}
			searchFields={searchFields}
		/>
}


export default BannerPage;
